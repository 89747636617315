import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import CapableImage from "../../assets/images/capable.png";

// import styles from "../../global.scss";
import "./styles.scss";

const CapabilitiesPage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

  return (
    <Container maxWidth="xl">
      <Box className="capable">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Capabilities</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={CapableImage}
              alt='capable'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box
          sx={{ maxWidth: "852px", width: "100%", margin: "auto" }}>

        <Box className="capable__section"
          id={"ach-transfer"}>
          <Typography variant='h5' mb={"40px"}>ACH Transfer</Typography>
          <Typography variant='body1' sx={{ color: "grey" }}>
            Send money fast with Lusid&#39;s 3-business days or Next day or Holiday/Saturday delivery
            ACH transfers. Quickly and securely move funds between bank accounts, perfect for
            payroll, vendor payments, or customer collections. Enjoy lower fees compared to wire
            transfers or other service providers and streamline your cash flow management.
          </Typography>
        </Box>


        {/* <Box>
          <Container
            maxWidth="md">
              <Box
                className="flexCenterSBRow"
                gap={"30px"}>
                <Typography variant='h4' mb={"40px"}>ACH Transfer</Typography>
                <Typography variant='body1' sx={{ color: "grey" }}>
                  Send money fast with Lusid&#39;s 3-business days or Next day or Holiday/Saturday delivery
                  ACH transfers. Quickly and securely move funds between bank accounts, perfect for
                  payroll, vendor payments, or customer collections. Enjoy lower fees compared to wire
                  transfers or other service providers and streamline your cash flow management.
                </Typography>
              </Box>
          </Container>
        </Box> */}


        <Box className="capable__section"
          id={"card-to-account"}>
          <Typography variant='h5' mb={"30px"}>Card to Account</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Embracing the Future: The Rise of Tap to Pay</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Maximize your cash flow by paying bills with your credit card, even if vendors only
            accept bank transfers. Earn rewards on business expenses while extending your
            working capital. Lusid handles the conversion, ensuring vendors receive payments their
            preferred way.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"international-payments"}>
          <Typography variant='h5' mb={"30px"}>International Payments</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>The Future of Effortless Shopping</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Expand globally with Lusid&#39;s foreign currency payments. Pay international vendors in
            their local currency or USD, reducing forex fees and simplifying cross-border
            transactions. Enjoy competitive exchange rates and fast processing times to over 180
            countries.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"international-collection"}>
          <Typography variant='h5' mb={"30px"}>International Collections</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Streamline Your Bill/Invoice Management</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Simplify global receivables with Lusid&#39;s international collections. Accept payments from
            worldwide customers in multiple currencies, consolidating funds into your preferred
            account. Streamline reconciliation and reduce currency conversion costs.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"accounting-integration"}>
          <Typography variant='h5' mb={"30px"}>Accounting Integration</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Spend Management for Individuals & SMBs with Innovative SaaS Solution</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Seamlessly sync payments with popular accounting software like QuickBooks, Xero,
            and NetSuite. Automate data entry, reduce errors, and save time on bookkeeping. Real-
            time syncing ensures your financial records are always up-to-date.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"invoice-payment-link"}>
          <Typography variant='h5' mb={"30px"}>Invoice Payment Link</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Simplifying Payee / Vendor Management for Individuals / */}
{/* SMBs</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Generate custom payment links to embed in invoices, emails, or your website. Allow
            customers to pay using their preferred method—credit card, or ACH bank
            transfer—without creating an account. Accelerate collections and improve cash flow.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"approval-workflow"}>
          <Typography variant='h5' mb={"30px"}>Approval Workflow</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Implement custom approval processes for enhanced financial control. Set up multi-level
            approvals, assign user roles, and manage permissions to ensure proper oversight of all
            payments. Approve transactions on-the-go with our mobile app.
          </Typography>
        </Box>
{/*  */}

        <Box className="capable__section"
          id={"security"}>
          <Typography variant='h5' mb={"30px"}>Security</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Protect your financial data with bank-level encryption and fraud detection systems.
            Lusid complies with PCI DSS and implements multi-factor authentication to safeguard
            your transactions. Enjoy peace of mind with our commitment to data security.
          </Typography>
        </Box>


        <Box className="capable__section"
          id={"easy-bill-capture"}>
          <Typography variant='h5' mb={"30px"}>Easy Bill Capture</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Our cutting-edge Bill Capture solution is a robust tool meticulously crafted to streamline
            and automate your bill and invoice management process. Leveraging with AI-powered
            bill capture, it swiftly and accurately extracts data from invoices, eliminating the need for
            manual data entry.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"auto-bill"}>
          <Typography variant='h5' mb={"30px"}>Auto-Bill</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Automate vendor payments with Lusid&#39;s auto-bill feature which generates bill from your
            customer’s invoice. Schedule regular payments for utilities, subscriptions, or vendor
            invoices to ensure timely processing. Reduce late fees and manual work while
            maintaining control over your cash flow.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"recurring-payments"}>
          <Typography variant='h5' mb={"30px"}>Recurring Payments</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Set up and manage recurring payment schedules for customers or clients. Ideal for
            subscription services, membership fees, or retainer agreements. Customize frequency,
            amount, and duration while providing a seamless experience for your customers.
          </Typography>
        </Box>

        <Box className="capable__section"
          id={"vendor-management"}>
          <Typography variant='h5' mb={"30px"}>Vendor management</Typography>
          {/* <Typography variant='h6' mb={"20px"} sx={{ textAlign: "center" }}>Revolutionize Your Spending with Spend Automation</Typography> */}
          <Typography variant='body1' sx={{ color: "grey" }}>
            Vendor management offers a comprehensive view of your financial obligations,
            providing you with a transparent understanding of your expenses. By consolidating all
            your payees in a single location, you gain valuable insights that enable you to budget
            more effectively and make well-informed financial choices.
          </Typography>
        </Box>

        </Box>


      </Box>
    </Container>
  )
}

export default CapabilitiesPage;