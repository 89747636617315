import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Box, 
  Container, 
  Typography 
} from '@mui/material';

import SolutionsImage from "../../assets/images/products.png";

import "./styles.scss";

const SolutionsPage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

  return (
    <Container maxWidth="xl">
			<Box className="solutions">
        {/* <Box sx={{ margin: "15px 0px 100px 0px", borderRadius: "30px", backgroundColor: "#f1f1f1", height: "40vh" }} className="flexCenterCenterRow">
          <Typography variant='h3'>Solutions</Typography>
        </Box> */}

          <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Solutions</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={SolutionsImage}
              alt='solutions'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>


        <Box
          sx={{ maxWidth: "852px", width: "100%", margin: "auto" }}>
         
          <Box className="solutions__section"
            id={"supplier-network"}>
            <Typography variant='h5' mb={"30px"}>
              Supplier Network
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Expand your business reach with Lusid&#39;s extensive supplier network. Connect with
              verified suppliers across various industries, streamlining your procurement process. Our
              platform enables seamless transactions, automated payments, and real-time
              communication with your suppliers, fostering stronger business relationships and
              reducing operational costs.
            </Typography>
          </Box>

          <Box className="solutions__section"
            id={"pay-by-business-handle"}>
          <Typography variant='h5' mb={"30px"}>
              Pay by Business handle
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Simplify your payment process with Lusid&#39;s innovative &quot;Pay by Business handle&quot;
              feature. No more juggling account numbers or routing information. Simply enter the
              recipient&#39;s business name, and our intelligent system matches it with the correct
              payment details. This feature reduces errors, saves time, and ensures your payments
              reach the right destination quickly and securely.
            </Typography>
          </Box>

          <Box className="solutions__section"
            id={"improve-your-cashflow"}>
          <Typography variant='h5' mb={"30px"}>
              Improve Your Cashflow
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Optimize your financial health with Lusid&#39;s cashflow management tools. Gain real-time
              visibility into your inflows and outflows, automate invoice processing, and schedule
              payments strategically. Our advanced analytics provide actionable insights to help you
              make informed decisions, identify cost-saving opportunities, and maintain a healthy
              cash balance for sustainable growth.
            </Typography>
          </Box>

          <Box className="solutions__section"
            id={"payment-flexibility"}>
          <Typography variant='h5' mb={"30px"}>
              Payment Flexibility
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Adapt to your business needs with Lusid&#39;s flexible payment options. Choose from a
              variety of payment methods including ACH, RTP, FedNow, credit cards, and digital
              wallets. Set up recurring payments, schedule future transactions, or initiate transfers at
              once. Our system accommodates both domestic and international payments, ensuring
              you have the flexibility to manage your finances efficiently.
            </Typography>
          </Box>        

          <Box className="solutions__section"
            id={"payment-reminders"}>
          <Typography variant='h5' mb={"30px"}>
              Payment Reminders
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Shorten DSO (Days Sales Outstanding) with Lusid&#39;s smart reminder system. Set up
              customized alerts for upcoming, or overdue invoices, and important financial deadlines.
              Our platform sends timely notifications along with invoices, supporting documents and

              payment history via email, SMS, or push notifications, thus building trust and encourage
              timely payments.
            </Typography>
          </Box>        
          
          <Box className="solutions__section"
            id={"1099-contractor-payment"}>
          <Typography variant='h5' mb={"30px"}>
              1099 Contractor Payment
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Simplify 1099 contractor payments with Lusid&#39;s dedicated solution. Easily manage
              multiple contractors, set up custom payment schedules, and ensure timely
              compensation. Our platform provides detailed payment reports, and offers flexible
              payment options to suit various contractor arrangements, streamlining your workforce
              management.
            </Typography>
          </Box>     

          <Box className="solutions__section"
            id={"rent-lease-payments"}>
          <Typography variant='h5' mb={"30px"}>
              Rent/Lease Payments
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Manage your rent and lease obligations effortlessly with Lusid. Set up automated
              monthly payments, track lease terms, and manage security deposits all in one place.
              Our system sends timely reminders for upcoming payments, helping you maintain good
              standing with property managers and avoid any disruptions to your business operations.
            </Typography>
          </Box>        
          
          <Box className="solutions__section"
            id={"utility-payments"}>
          <Typography variant='h5' mb={"30px"}>
              Utility Payments
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Streamline your utility bill management with Lusid&#39;s utility payment solution. Consolidate
              all your utility accounts, schedule automatic payments, and connect QuickBooks or
              Xero or NetSuite so all your utility payments automatically sync with your books. Our
              system helps you ensures timely payments to avoid service interruptions, and provides
              detailed reports for better budget planning.
            </Typography>
          </Box>        
        </Box>
              
      </Box>
		</Container>
  )
}

export default SolutionsPage