import React from 'react';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import AboutImage from "../../assets/images/about.png";

// import styles from "../../global.scss";
import "./styles.scss";

const AboutPage = () => {
  return (
		<Container maxWidth="xl">
			<Box className="about">
				<Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>About us</Typography>
					<Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						   src={AboutImage}
							 alt='about'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

		 <Box
			sx={{ maxWidth: "852px", width: "100%", margin: "auto" }}>
				<Typography variant='h5' mb={"10px"}>
					Story behind the name Lusid
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					When we were brainstorming names for our company, we wanted something that would reflect our core values and the essence of what we aim to achieve in the Fintech industry. We chose the name Lusid because it is a play on the word "lucid," which means expressed clearly or easy to understand.
					<br/><br/>
					Additionally, the name Lusid has a modern and distinctive sound, which aligns with our vision of being a forward-thinking and cutting-edge fintech company that aims to bring a fresh perspective to the industry, combining technology, data, frictionless interfaces, and automation.
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					Who We Are
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					Lusid takes pride in being a dynamic and innovative Fintech company. With a team of passionate experts, we are fully equipped to serve our loyal customers. Our journey began with a simple vision: to deliver exceptional products and services that surpass expectations and leave a lasting impression.
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					Our Mission
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					At Lusid, our mission is to revolutionize the Payment industry. We strive to be pioneers, setting new standards and challenging the status quo. We believe in fostering long-lasting relationships with our customers and partners, built on trust, transparency, and the delivery of exceptional results.
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					What Sets Us Apart
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					<ul style={{ marginLeft: "40px" }}>
						<li>
							<strong>Expertise</strong>: Our team comprises highly skilled professionals who are experts in their respective fields. 
						</li>
						<li>
							<strong>Innovation</strong>: Our relentless pursuit of improvement and staying ahead of the curve enables us to deliver cutting-edge solutions that outshine our competitors.
						</li>
						<li>
							<strong>Customer Focus</strong>: Our clients are of utmost importance to us. We believe in cultivating strong, mutually beneficial relationships. 
						</li>
						<li>
							<strong>Flexibility</strong>: We recognize that one size does not fit all. Hence, we offer flexible and customizable solutions just for you.
						</li>
					</ul>	
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					Our Team
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					Behind every successful company lies an exceptional team. At Lusid, we have a group of individuals who form the backbone of our organization. We foster a collaborative and inclusive environment, encouraging creativity and innovation at every step.
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					Join Us on Our Journey
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					We firmly believe that success is a journey, not a destination. We are constantly evolving, adapting, and embracing change. Together with our clients, we aim to create a brighter and better future.
					<br/><br/>
 					Whether you seek a partnership, new opportunities, or simply wish to learn more about our endeavors, we are here for you. Reach out to our friendly team today and let us embark on this exciting journey together!
				</Typography>

				<Typography variant='h5' mb={"10px"}>
					Conclusion
				</Typography>
				<Typography variant='body1' sx={{ color: "grey" }} mb={"40px"}>
					We sincerely appreciate your time in getting to know our company better. We hope this glimpse into our world has piqued your interest and shed light on what sets us apart. Remember, we are not just another company; we are a team of passionate individuals committed to making a difference. Stay tuned for more exciting updates, as the journey with Lusid has only just begun!
				</Typography>
			</Box>
			</Box>
		</Container>
  )
}

export default AboutPage;