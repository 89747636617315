import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { 
  Box,
	Container, 
	Typography 
} from '@mui/material';

import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import packageJson from "../../package.json"; 

//routes
import HomePage from '../views/homePage/HomePage';
import AboutPage from '../views/aboutPage/AboutPage';
import ContactPage from '../views/contactPage/ContactPage';
import PrivacyPolicyPage from '../views/privacyPolicyPage/PrivacyPolicyPage';
import TermsAndConditionsPage from '../views/termsAndConditionsPage/TermsAndConditionsPage';
// import ProductsPage from '../views/productsPage/ProductsPage';
import CapabilitiesPage from '../views/capabilitiesPage/CapabilitiesPage';
import SolutionsPage from '../views/solutionsPage/SolutionsPage';
import DevelopersPage from '../views/developersPage/DevelopersPage';
import SecurityPage from '../views/securityPage/SecurityPage';

import AccountsPayablePage from '../views/productsPage/accountsPayablePage/AccountsPayablePage';
import AccountsReceivablePage from '../views/productsPage/accountsReceivablePage/AccountsReceivablePage';
// import ExpenseManagementPage from '../views/productsPage/expenseManagementPage/ExpenseManagementPage';

import PricingPage from '../views/pricingPage/PricingPage';

const MainRoutes = () => {
  
  return (
    <Router>
			<NavBar />
      <Box>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsAndConditionsPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          {/* <Route path="/products" element={<ProductsPage />} /> */}

          <Route path="/products/accounts-payable" element={<AccountsPayablePage />} />
          <Route path="/products/accounts-receivable" element={<AccountsReceivablePage />} />
          {/* <Route path="/products/expense-management" element={<ExpenseManagementPage />} /> */}

          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/developers" element={<DevelopersPage />} />
          <Route path="/capabilities" element={<CapabilitiesPage />} />
          <Route path="/security" element={<SecurityPage />} />
          <Route path="/version" element={<AppVersion />} />
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>
      </Box>
			<Footer />
    </Router>
  )
}

export default MainRoutes;

// 404 page
const NotFoundPage = () => {
  return(
		<Container maxWidth="xl">
      <Box sx={{ height: "calc(100vh - 100px)" }} className="flexCenterCenterRow">
			  <Typography variant='h3'>404 - Page not found</Typography>
      </Box>
		</Container>
  )
};

const AppVersion = () => {
  return(
    <Box
      sx={{
        width: "100vw",
        height: "100vh", 
        position: "absolute", 
        zIndex: "1000",
        top: "0px",
        backgroundColor: "white"
      }}>
      <Typography
        color={"black"}>
        App version: {packageJson.version}
      </Typography>
    </Box>
  )
};