import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
	Box, 
	Button, 
	Container,
	Drawer,
	IconButton,
	Link,
} from '@mui/material';

import Logo from "../../assets/icons/logo-blue-dark2.svg";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import NavPopover from '../popovers/NavPopover';

import "./styles.scss";

const NavBar = () => {
	const navigate = useNavigate();
	const [navDrawerOpen, setNavDrawerOpen] = useState(false);

	const [popoverOpen, setPopoverOpen] = useState(false);
	const [popoverAnchor, setPopoverAnchor] = useState(null);
	const [sectionSelected, setSectionSelected] = useState("");

	const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY + 5) { // if scroll down hide the navbar
      setShow(false); 
    } else if (window.scrollY < lastScrollY) { // if scroll up show the navbar
      setShow(true);  
    }
		setLastScrollY(window.scrollY); 
  };

  useEffect(() => {
		window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

	const handleNavDrawerClose = () => {
		setNavDrawerOpen(false);
		console.log(process.env.REACT_APP_DEVELOPER_WEB_URL);
		console.log(process.env.REACT_APP_CORE_WEB_URL);
	}

  return (
		show
		?
		<Box className="navbar" 
			sx={{ boxShadow:  window.scrollY > 0 ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : "#fff 0px 0px"}}>
			<NavPopover
				open={popoverOpen}
				setOpen={setPopoverOpen}
				anchor={popoverAnchor}
				setAnchor={setPopoverAnchor}
				select={sectionSelected}
				handleClick={handleNavDrawerClose}
				/>
			
			<Container
				maxWidth="xl">
				<Box className="flexCenterSBRow" sx={{ padding: "15px 0px 15px 0px" }}>
					<Box
						sx={{ cursor: "pointer" }}
						onClick={() => navigate("/")}>
						<img
							src={Logo}
							alt="lusid-logo"
							style={{ width: "140px" }}
							/>
					</Box>

					<Box 
						className="flexCenter_Row" 
						sx={{ 
							gap: "20px",
							display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
						}}>
							<Box className="navbar__item"
								onMouseEnter={(e) => {
									setPopoverAnchor(e.currentTarget);
									setSectionSelected("products");
									setPopoverOpen(true);
								}}>
								<span>Products</span>
							</Box>
							<Box className="navbar__item"
								onMouseEnter={() => {
									setPopoverOpen(false);
								}}
								onClick={(e) => {
									navigate("/solutions");
									setPopoverOpen(false);
									// setPopoverAnchor(e.currentTarget);
									// setSectionSelected("solutions");
									// setPopoverOpen(true);
								}}>
								<span>Solutions</span>
							</Box>
							<Box className="navbar__item"
								onClick={(e) => {
									document.body.scrollTop = 0; // For Safari
  	  								document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
									navigate("/pricing");
									setPopoverOpen(false);
								}}>
								<span>Pricing</span>
							</Box>
							<a 
								href={process.env.REACT_APP_DEVELOPER_WEB_URL}
								style={{ textDecoration: "none", color: "black"}}
								target="_blank" rel="noopener noreferrer"
								onMouseEnter={() => {
									setPopoverOpen(false);
								}}>
								<Box className="navbar__item">
									<span>Developers</span>
								</Box>
							</a>
							<Box className="navbar__item"
								onMouseEnter={(e) => {
									setPopoverAnchor(e.currentTarget);
									setSectionSelected("company");
									setPopoverOpen(true);
								}}>
								<span>Company</span>
							</Box>

							<Link 
								href={process.env.REACT_APP_CORE_WEB_URL}
								target="_blank" rel="noopener noreferrer"
								>
								<Button
									variant='contained'>
									Sign In
								</Button>
							</Link>						
					</Box>
					<IconButton
						className="flexCenterCenterRow"
						onClick={() => setNavDrawerOpen(true)}
						sx={{ 
							width: "56px",
							height: "56px",
							display: { xs: "block", sm: "block", md: "none", lg: "none" },
							paddingTop: "10px",
							"&:hover": {
								backgroundColor: "#f1f1f1"
							}
						}}>
						<MenuIcon fontSize='large'/>
					</IconButton>
					<Drawer
						open={navDrawerOpen}
						anchor="right"
						onClose={handleNavDrawerClose}>
						<Box
							className="flex_FSColumn"
							sx={{
								width: "70vw",
								padding: "10px 10px 10px 10px"
							}}>
							<IconButton
								onClick={handleNavDrawerClose}
								sx={{ 
									width: "56px",
									height: "56px",
									display: { xs: "block", sm: "block", md: "none", lg: "none" },
									paddingTop: "10px",
									"&:hover": {
										backgroundColor: "#f1f1f1"
									}
								}}>
								<CloseIcon fontSize='large'/>
							</IconButton>
							<Box 
								sx={{ width: "100%", gap: "20px" }}
								className="flexCenterCenterColumn">
								<Box className="navbar__item"
									onMouseEnter={(e) => {
										// handleNavDrawerClose();
										setPopoverAnchor(e.currentTarget);
										setSectionSelected("products");
										setPopoverOpen(true);
									}}>
									<span>Products</span>
								</Box>
								<Box className="navbar__item"
									onMouseEnter={() => {
										setPopoverOpen(false);
									}}
									onClick={(e) => {
										handleNavDrawerClose();
										navigate("/solution");
										setPopoverOpen(false);
									}}>
									<span>Solutions</span>
								</Box>
								<a 
									href={process.env.REACT_APP_DEVELOPER_WEB_URL}
									style={{ textDecoration: "none", color: "black"}}
									target="_blank" rel="noopener noreferrer"
									onMouseEnter={() => {
										setPopoverOpen(false);
									}}
									onClick={() => {
										handleNavDrawerClose();
									}}>
									<Box className="navbar__item">
										<span>Developers</span>
									</Box>
								</a>
								<Box className="navbar__item"
									onMouseEnter={(e) => {
										// handleNavDrawerClose();
										setPopoverAnchor(e.currentTarget);
										setSectionSelected("company");
										setPopoverOpen(true);
									}}>
									<span>Company</span>
								</Box>
								<Link 
									href={process.env.REACT_APP_CORE_WEB_URL}
									target="_blank" rel="noopener noreferrer"
									>
									<Button
										onClick={() => handleNavDrawerClose()}
										variant='text'>
										Sign In
									</Button>
								</Link>						
							</Box>
						</Box>
					</Drawer>
				</Box>
			</Container>
		</Box>
		:
		<Box
			sx={{ minHeight: "80px"}}>
				
		</Box>
  )
}

export default memo(NavBar);